import React from "react";
import styled from "styled-components";

const height = "4rem";

const Spacer = styled.div`
  height: ${height};
`;

const Component = styled.div`
  height: ${height};
  background-color: ${(props) => props.theme.colors.grey};
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
`;

const Title = styled.h1`
  background-color: transparent;
  margin: auto;
  margin-right: auto;
  margin-left: 5%;
  height: fit-content;
  color: white;
`;

export const TopBar = () => {
  return (
    <>
      <Spacer />
      <Component>
        <Title>IV Compatibility Tool</Title>
      </Component>
    </>
  );
};
