import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { LumenDisplay } from "../main-page-react/Result/LumenDisplay";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const StyledLumenDisplay = styled.div`
    border-bottom: thick black solid;
    margin-bottom: 3rem;
`;

const LumenDisplayLumenNumber = styled.h3``;

const LumenDisplayMedication = styled.p``;

type LumenDisplayProps = {
    lumenNumber: number;
    meds: any;
};

const LumenDisplayPrintPage = ({ lumenNumber, meds }: LumenDisplayProps) => {
    return (
        <StyledLumenDisplay>
            <LumenDisplayLumenNumber>Lumen {lumenNumber}</LumenDisplayLumenNumber>
            {
                meds.map(ele =>
                    <LumenDisplayMedication>{ele}</LumenDisplayMedication>
                )
            }
        </StyledLumenDisplay>
    );
}


const Component = styled.div``;

type PrintPageProps = {
    lumensWithMedications: any;
    onEndPrintPage: () => void;
};

export const PrintPage = ({ lumensWithMedications, onEndPrintPage }: PrintPageProps) => {

    useEffect(() => {
        window.print();
    }, [])

    return (
        <Component>
            {onEndPrintPage()}
            <DragDropContext onDragEnd={() => { }}>
                {
                    lumensWithMedications.map(({ meds, lumenPortNumber }) =>
                        <LumenDisplayPrintPage meds={meds} lumenNumber={lumenPortNumber} />
                    )
                }
            </DragDropContext>
        </Component>
    );
}