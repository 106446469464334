import axios from 'axios';
import { getAPIURL } from './getAPIURL';

export const tokenCheckAPICall = async () => {
    
    try {
        const res = await axios({
            method: "post",
            url: `${getAPIURL()}/tokenCheck/`,
            withCredentials: true,
            headers: {
                credentials: 'include'
            }
        })
        if (res.status != 200) throw res.data.data.message;
        return {};
    } catch (err) {
        // NO error message as an error would most likely just mean the user is not logged in.
        return { error: true };
    }
}