import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MedicationType } from "../MainPage";

const LumenPort = styled.p`
  margin: 0;
  width: 25%;
  text-align: left;
  color: #3b414c;
  font-weight: bolder;
  font-size: 1.1rem;
  font-family: ${(props) => props.theme.font.family.paragraph};
`;

type StyledDroppableItemProps = {
  isDraggingOver: boolean;
  needsCaution: boolean;
};

const StyledDroppableItem = styled.div<StyledDroppableItemProps>`
  display: flex;
  flex-wrap: wrap;
  background: ${(props) => (props.isDraggingOver ? "#f7f9fd" : "transparent")};
  padding: grid;
  width: 100%;
  ${({ needsCaution }) => needsCaution ?
    `
    border: 2px solid yellow;
  ` :
    `
    border-bottom: 2px solid lightgrey;
  `};
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  padding-top: 1rem;
  padding-left: 0.5rem;
`;

const DraggleContent = styled.div`
  width: 75%;
`;

const grid = 8;

type StyledDraggableItemProps = {
  isDragging: boolean;
};

const StyledDraggableItem = styled.div<StyledDraggableItemProps>`
  width: 100%;
  height: 2rem;
  user-select: none;
  margin: 0 0 ${grid}px 0;
  background: ${(props) => (props.isDragging ? "#dce3f1" : "transparent")};
  display: flex;
  justify-content: flex-start;
`;

const StyledDraggableItemLine = styled.div`
  margin-left: 0;
  margin-right: 0;
  height: 100%;
  width: 3px;
  background-color: black;
`;

const StyledDraggableItemText = styled.p`
  margin: auto;
  margin-left: 0.5rem;
  margin-right: 0;
  font-size: 1.25rem;
  font-family: ${(props) => props.theme.font.family.paragraph};
`;

type LumenDisplayProps = {
  lumenPortNumber: number;
  meds: string[];
  needsCaution: boolean;
};

export const LumenDisplay = ({
  lumenPortNumber,
  meds,
  needsCaution
}: LumenDisplayProps) => {
  return (
    <Droppable droppableId={`droppable-${lumenPortNumber}`}>
      {(provided, snapshot) => (
        <StyledDroppableItem
          {...provided.droppableProps}
          ref={provided.innerRef}
          isDraggingOver={snapshot.isDraggingOver}
          needsCaution={needsCaution}
        >
          <LumenPort>Lumen {lumenPortNumber}</LumenPort>
          <DraggleContent>
            {meds.map((name, i) => (
              <Draggable
                key={lumenPortNumber}
                draggableId={`draggable-${name}-${lumenPortNumber}`}
                index={i}
              >
                {(provided, snapshot) => (
                  <StyledDraggableItem
                    ref={provided.innerRef}
                    isDragging={snapshot.isDragging}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{ ...provided.draggableProps.style }}
                  >
                    <StyledDraggableItemLine />
                    <StyledDraggableItemText>{name}</StyledDraggableItemText>
                  </StyledDraggableItem>
                )}
              </Draggable>
            ))}
          </DraggleContent>
          {provided.placeholder}
        </StyledDroppableItem>
      )}
    </Droppable>
  );
};
