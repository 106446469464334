import React from "react";
import styled from "styled-components";
import { MedicationType } from "../MainPage";
import { RiCloseLine } from "react-icons/ri";
import LoadingAnimation from '../assets/loadingAnimation.svg';

const Component = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const StyledSelectedMedication = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0.5rem 0;
  margin-right: 0.5rem;
  min-width: 7.5rem;
  height: 2rem;
  padding: 0 0.25rem;
  border: thin solid #a6c6df;
  background-color: #f6fcff;
`;

const SelectedMedicationName = styled.p`
  font-family: ${(props) => props.theme.font.family.paragraph};
  margin: auto 0;
`;

const SelectedMedicationRemoveIcon = styled(RiCloseLine)`
  margin: auto;
  margin-right: 0;
`;

const StyledLoadingAnimation = styled.img`
  height: 2.5rem;
`;

type SelectedMedicationProps = {
  name: string;
  removeMedication: (medicationName: string) => void;
};

const SelectedMedication = ({
  name,
  removeMedication,
}: SelectedMedicationProps) => {
  return (
    <StyledSelectedMedication key={name}>
      <SelectedMedicationName>{name}</SelectedMedicationName>
      <SelectedMedicationRemoveIcon
        size={"1.75rem"}
        onClick={() => {
          removeMedication(name);
        }}
      />
    </StyledSelectedMedication>
  );
};

type SelectedMedicationsProps = {
  removeMedication: (medicationName: string) => void;
  selectedMedications: string[];
  loading: boolean;
};

export const SelectedMedications = ({
  removeMedication,
  selectedMedications,
  loading
}: SelectedMedicationsProps) => {
  return (
    <Component>
      {selectedMedications.map((ele) => (
        <SelectedMedication name={ele} removeMedication={removeMedication} />
      ))}
      {loading && <StyledLoadingAnimation src={LoadingAnimation} />}
    </Component>
  );
};
