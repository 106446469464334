import React, { useEffect, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AppContext } from "./AppContext";
import {
  MessageToUser,
  MessageToUserProps,
} from "./packages/message-to-user-react/src/MessageToUser";
import { LoadingComponent } from "./packages/loading-react/src/loading";
import { MainPage } from "./packages/main-page-react/";
import { LoginPage } from "./packages/login-react-page/LoginPage";
import { tokenCheckAPICall } from './packages/api-calls/tokenCheck';

export const MobileBreakPoint = 700;
export const DesktopWidth = "65rem";

const AppStyle = styled.div`
  background-color: ${(props) => props.theme.colors.primaryBackground};
`;

export const AppTheme = {
  colors: {
    primaryText: "#000",
    primaryBackground: "#fff",
    success: "green",
    error: "red",
    grey: "#3b414c",
  },
  font: {
    family: {
      title: "'Poppins', sans-serif",
      subTitle: "'Poppins', sans-serif",
      paragraph: "'Poppins', sans-serif",
    },
    size: {
      // this needs to be decided
      title: "3rem",
      subTitle: "3rem",
      paragraph: "3rem",
    },
  },
  screenSizes: {
    mobile: `(max-width: ${MobileBreakPoint}px)`,
  },
  desktopWidth: DesktopWidth,
};

export default () => {
  const [isLoading, setIsLoading] = useState(true);
  const [messageToUser, setMessageToUser] = useState<MessageToUserProps>(null as any);
  const [allowUserAccess, setAllowUserAccess] = useState(false);

  useEffect(() => {
    tokenCheck();
  }, [])

  const login = () => {
    setAllowUserAccess(true);
  }

  const tokenCheck = async () => {
    const tokenCheckRes = await tokenCheckAPICall();
    if (!tokenCheckRes.error) setAllowUserAccess(true);
    setIsLoading(false);
  }

  if (!isLoading) {
    return (
      <ThemeProvider theme={AppTheme}>
        <AppStyle>
          <AppContext.Provider
            value={{
              setMessageToUser: setMessageToUser as any,
            }}
          >
            {!allowUserAccess && <LoginPage login={login} />}
            {allowUserAccess && <MainPage />}
            <MessageToUser {...messageToUser} />
            <LoadingComponent isLoading={isLoading} />
          </AppContext.Provider>
        </AppStyle>
      </ThemeProvider>
    );
  } else {
    return <>Loading...</>
  }
};
