import React, { useContext, useState } from "react";
import styled from "styled-components";
import { TopBar } from "./TopBar";
import { IVMedicationFluidSelection } from "./IVMedicationFluidSelection/IVMedicationFluidSelection";
import { LumenCountSelector } from "./LumenCountSelector/LumenCountSelector";
import { ResultDisplay } from "./Result/ResultDisplay";
import CompatibilityJSON from '../../IV-Compatibility.json';
import { AppContext } from "../../AppContext";
import { PrintPage } from "../print-page-react/PrintPage";
import { createLumensAPICall } from "../api-calls/createLumensAPICall";
import { checkMedicationCompatibilityAPICall } from "../api-calls/checkMedicationCompatibilityAPICall";

const Page = styled.div`
  height: 89vh;
  width: 95%;
  margin: auto;
  margin-top: 5%;
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 80vh;
`;

const DividerLine = styled.div`
  height: 100%;
  width: 0.3%;
  background-color: black;
`;

export type MedicationType = {
  name: string;
  active?: boolean;
};

export type LumenPortsType = {
  lumenPortNumber: number,
  meds: MedicationType[],
  needsCaution: boolean;
}[];

// return
// 0 = not compatible
// 1 = compatible
// 2 = compatible, but caution
export const checkIfTwoMedicationsAreCompatiltyWithEachOther = async (medication1: string, medication2: string) => {
  return await checkMedicationCompatibilityAPICall(medication1, medication2);
}

// ["Atropine", "Amiodarone(Cordarone)", "Argatroban", "Dobutamine (Dobutrex)", "Epinephrine (Adrenalin)", "Heparin"] as string[]
export const MainPage = () => {
  const { setMessageToUser } = useContext(AppContext);
  const [selectedMedications, setSelectedMedications] = useState([] as string[]);
  const [numberOfLumenPorts, setNumberOfLumenPorts] = useState(-1);
  const [lumenPorts, setLumenPorts] = useState([] as LumenPortsType);
  const [displayPrintPage, setDisplayPrintPage] = useState(false);
  const [loading, setLoading] = useState(false);

  const addMedication = async (medicationName: string) => {
    if (!selectedMedications.find(ele => ele == medicationName)) {
      if (await createLumens([...selectedMedications, medicationName], numberOfLumenPorts)) {
        setSelectedMedications([...selectedMedications, medicationName]);
        return true;
      } else {
        return false;
      }
    } else {
      setMessageToUser({ message: "Medication already added", variant: "error" })
      return false;
    }
  };

  // TODO move outside of component maybe to separate file
  // Creates the results and return boolean if you can add medication to list
  const createLumens = async (updatedSelectedMedications, newNumberOfLumenPorts) => {
    setLoading(true);
    if (updatedSelectedMedications.length != 0) {
      try {
        if (newNumberOfLumenPorts <= 0) throw "Please select number of lumens.";
        const res = await createLumensAPICall(updatedSelectedMedications, newNumberOfLumenPorts);
        if (res && res.error) throw res.error;
        res.lumens = res.lumens.map((ele, i) => {
          return { meds: ele, needsCaution: false, lumenPortNumber: i + 1 }
        })
        setLumenPorts(res.lumens);
        setLoading(false);
        return true;
      } catch (err) {
        if (typeof err != "string") setMessageToUser({ variant: "error", message: "Error can't process request" });
        else setMessageToUser({ variant: "error", message: err });
        setLoading(false);
        return false;
      }
    }
    setLoading(false);
  }

  // TODO better variable name then newMedications
  // This takes the current array and finds the medicine with the greatest number of matched 
  const findBestResult = (newMedications) => {

  }

  const removeMedication = (medicationName: string) => {
    const newSelectedMedications = selectedMedications.filter((ele) => {
      return ele != medicationName;
    })
    setSelectedMedications(
      newSelectedMedications
    );
    createLumens(newSelectedMedications, numberOfLumenPorts);
  };

  const onNumberOfLumenPortsChange = async (newNumberOfLumenPorts: number) => {
    if (selectedMedications.length == 0) setNumberOfLumenPorts(newNumberOfLumenPorts);
    else {
      if (await createLumens(selectedMedications, newNumberOfLumenPorts)) {
        setNumberOfLumenPorts(newNumberOfLumenPorts);
      }
    }
  }

  const reset = () => {
    setLumenPorts([]);
    setSelectedMedications([]);
    setNumberOfLumenPorts(-1);
  }

  if (!displayPrintPage) {
    return (
      <Page>
        <TopBar />
        <Content>
          <LumenCountSelector
            numberOfLumenPorts={numberOfLumenPorts}
            onNumberOfLumenPortsChange={onNumberOfLumenPortsChange}
          />
          <DividerLine />
          <IVMedicationFluidSelection
            addMedication={addMedication}
            removeMedication={removeMedication}
            selectedMedications={selectedMedications}
            loading={loading}
          />
          <DividerLine />
          <ResultDisplay onReset={reset} onPrintPage={() => { setDisplayPrintPage(true) }} setLumenPorts={setLumenPorts} lumenPorts={lumenPorts} />
        </Content>
      </Page>
    );
  } else {
    return (<PrintPage onEndPrintPage={() => setDisplayPrintPage(false)} lumensWithMedications={lumenPorts} />);
  }
};
