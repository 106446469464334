import { Button, TextField } from "@material-ui/core";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { AppContext } from "../../AppContext";
import { loginAPICall } from "../api-calls/login";

const darkTheme = createMuiTheme({
    palette: {
        type: "dark",
    }
});

const Page = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
`;

const Content = styled.div`
    margin: auto;
    width: 25rem;
    padding: 2rem 0;
    background-color: #3b414c;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
`;

const Title = styled.h4`
    color: white;
    font-size: 1.5rem;
    margin: 0;
    margin-bottom: 1.5rem;
    text-align: center;
    font-family: ${(props) => props.theme.font.family.title};
`;

const CodeInputContainer = styled.div`
    margin: auto;
    display: flex;
    justify-content: center;
`;

const CodeInput = styled(TextField)`
    && {
        height: 2rem;
        font-family: ${(props) => props.theme.font.family.title};
    }
`;

const SubmitButton = styled(Button)`
    && {
        margin-left: 2rem;
        height: 2rem;
    }
`;

type LoginPageProps = {
    login: () => void
};

export const LoginPage = ({ login }: LoginPageProps) => {
    const { setMessageToUser } = useContext(AppContext);
    const [code, setCode] = useState("");

    const onLoginAttempt = async () => {
        const loginRes = await loginAPICall(code);
        if (!loginRes.error) login();
        else setMessageToUser({ message: "Invalid Code", variant: "error" });
    }

    const onCodeUpdate = (event: any) => setCode(event.target.value);

    return (
        <Page>
            <ThemeProvider theme={darkTheme}>
                <Content>
                    <Title>Please Input Your Code</Title>
                    <CodeInputContainer>
                        <CodeInput placeholder={"code"} value={code} onChange={onCodeUpdate} />
                        <SubmitButton onClick={onLoginAttempt} variant="contained">Submit</SubmitButton>
                    </CodeInputContainer>
                </Content>
            </ThemeProvider>
        </Page>
    );
}