import React, { useState, useContext } from "react";
import styled from "styled-components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { MedicationType } from "../MainPage";
import { SelectedMedications } from "./SelectedMedications";
import { names } from '../../../IV-Names.json';
import { AppContext } from "../../../AppContext";
const Component = styled.div`
  width: 38%;
`;

const Title = styled.h1`
  margin: 0;
  font-family: ${(props) => props.theme.font.family.title};
  color: ${(props) => props.theme.colors.primaryText};
`;

const Criteria = styled.p`
  font-family: ${(props) => props.theme.font.family.paragraph};
  font-weight: bolder;
  margin: 0.75rem 0;
  font-size: 1.1rem;
`;

type IVMedicationFluidSelectionProps = {
  selectedMedications: string[];
  addMedication: (medicationName: string) => Promise<boolean>;
  removeMedication: (medicationName: string) => void;
  loading: boolean;
};

const RenderTextInput = (props) => (
  <TextField
    {...props}
    label={"Search medications and fluids"}
    variant={"outlined"}
  />
);


export const IVMedicationFluidSelection = ({
  selectedMedications,
  addMedication,
  removeMedication,
  loading
}: IVMedicationFluidSelectionProps) => {
  const [autoCompleteValue, setAutoCompleteValue] = useState("");


  const onAddMedicationDropDownSelected = async (
    event: React.ChangeEvent<{}>,
    newValue: string | null
  ) => {
    if (newValue) await addMedication(newValue);
    setAutoCompleteValue("");
  };

  const onInputChange = (e: any) => {
    if (e != null) {
      if (typeof e.target.value == "string") setAutoCompleteValue(e.target.value);
    } else {
      setAutoCompleteValue("");
    }
  }

  return (
    <Component>
      <Title>IV Medications/Fluids</Title>
      <Criteria>Criteria {`(${selectedMedications.length})`}</Criteria>
      <SelectedMedications
        removeMedication={removeMedication}
        selectedMedications={selectedMedications}
        loading={loading}
      />
      <Autocomplete
        clearOnEscape
        clearOnBlur
        style={{ width: "100%", marginTop: "0.5rem" }}
        onChange={onAddMedicationDropDownSelected}
        options={names}
        inputValue={autoCompleteValue}
        onInputChange={onInputChange}
        renderInput={RenderTextInput}
      />
    </Component>
  );
};
