import axios from 'axios';
import { getAPIURL } from './getAPIURL';

export const createLumensAPICall = async (drugs: string[], numberOfLumens: number) => {
    try {
        const res = await axios({
            method: "post",
            url: `${getAPIURL()}/drug/createLumens`,
            withCredentials: true,
            headers: {
                credentials: 'include'
            },
            data: {
                drugs,
                numberOfLumens
            }
        })
        if (res.status != 200) throw res.data.data.message;
        if (res.data.notEnoughLumens) {
            return { error: "Not Enough Lumens" }
        }
        return { lumens: res.data.lumens };
        // return {};
    } catch (err) {
        console.log(err);
        // NO error message as an error would most likely just mean the user is not logged in.
        return { error: true };
    }
}