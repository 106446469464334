import axios from 'axios';
import { getAPIURL } from './getAPIURL';

export const checkMedicationCompatibilityAPICall = async (drug1: string, drug2: string) => {
    try {
        const res = await axios({
            method: "post",
            url: `${getAPIURL()}/drug/checkCompatibly`,
            withCredentials: true,
            headers: {
                credentials: 'include'
            },
            data: {
                drug1,
                drug2
            }
        })
        if (res.status != 200) return false;
        return res.data.valid;
    } catch (err) {
        console.log(err);
        return false;
    }
}