import React, { useContext, useState } from "react";
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { LumenDisplay } from "./LumenDisplay";
import { checkIfTwoMedicationsAreCompatiltyWithEachOther, MedicationType } from "../MainPage";
import { Button } from "@material-ui/core";
import { AppContext } from "../../../AppContext";
import { checkMedicationCompatibilityAPICall } from "../../api-calls/checkMedicationCompatibilityAPICall";

const Component = styled.div`
  width: 38%;
  height: 100%;
`;

const Title = styled.h1`
  margin: 0;
  margin-bottom: 1rem;
  color: ${(props) => props.theme.colors.primaryText};
  font-family: ${(props) => props.theme.font.family.title};
`;

const NoMedsSelectedText = styled.p`
  margin: 4rem auto;
  text-align: center;
  font-family: ${(props) => props.theme.font.family.paragraph};
`;

const ResetPrintContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin: auto;
  margin-top: 5rem;
  margin-bottom: 0;
`;

const ErrorButton = styled(Button)`
  && {
    background-color: #c73a3d !important;
    color: white;
    width: 6rem;
    font-family: ${(props) => props.theme.font.family.paragraph};
  }
`;

const PrintButton = styled(Button)`
  && {
    background-color: #586cdd !important;
    color: white;
    width: 6rem;
    font-family: ${(props) => props.theme.font.family.paragraph};
  }
`;

const FakeData = [
  {
    lumenPortNumber: 1,
    meds: [{ name: "hi" }, { name: "bye" }],
  },
  {
    lumenPortNumber: 2,
    meds: [{ name: "advil" }, { name: "moitron" }],
  },
];

type onDragMedicationEvent = {
  combine: boolean;
  destination: {
    droppableId: string;
    draggableId: string;
    index: number;
  };
  source: {
    droppableId: string;
    index: number;
  };
};

type LumenPortData = {
  lumenPortNumber: number;
  meds: MedicationType[];
}[];

type ResultDisplayProps = {
  lumenPorts: any;
  setLumenPorts: any;
  onPrintPage: () => void
  onReset: () => void;
}; // TODO any

export const ResultDisplay = ({ onReset, lumenPorts, setLumenPorts, onPrintPage }: ResultDisplayProps) => {
  const { setMessageToUser } = useContext(AppContext);

  const getMedicationOrFluid = (lumenPort, index) => {
    let medicationOrFluid = "error";
    lumenPorts.map((ele) => {
      if (ele.lumenPortNumber == lumenPort) {
        ele.meds.map((ele, i) => {
          if (i == index) {
            medicationOrFluid = ele;
          }
        });
      }
    });
    return medicationOrFluid;
  };

  const onDragMedication = async ({ destination, source }: onDragMedicationEvent) => {
    if (
      parseInt(destination.droppableId[destination.droppableId.length - 1]) !=
      parseInt(source.droppableId[source.droppableId.length - 1])
    ) {
      const medicationOrFluidToAdd = getMedicationOrFluid(
        parseInt(source.droppableId[source.droppableId.length - 1]),
        source.index
      );
      let newLumenPortState = [] as LumenPortData;
      let compatibilityCheckValue = true;
      newLumenPortState = await Promise.all(lumenPorts.map(async ({ meds, lumenPortNumber }) => {
        if (
          lumenPortNumber ==
          parseInt(destination.droppableId[destination.droppableId.length - 1])
        ) {
          await Promise.all(meds.map(async ele => {
            const compatibilityCheck = await checkMedicationCompatibilityAPICall(ele, medicationOrFluidToAdd);
            if (!compatibilityCheck) {
              compatibilityCheckValue = false;
            }
          }));
        }
      }));
      if (compatibilityCheckValue) {
        newLumenPortState = lumenPorts.map(({ lumenPortNumber, meds, needsCaution }) => {
          if (
            lumenPortNumber ==
            parseInt(destination.droppableId[destination.droppableId.length - 1])
          ) {
            // This is to add the medication to the lumen you want

            const newMedsFluids = [
              ...meds.slice(0, destination.index),
              ...meds.slice(destination.index, meds.length + 1),
              medicationOrFluidToAdd,
            ];
            return { lumenPortNumber, meds: newMedsFluids, needsCaution: false };

          } else if (
            lumenPortNumber ==
            parseInt(source.droppableId[source.droppableId.length - 1])
          ) {
            // This is to remove the medication from the lumen you got it from
            meds.splice(source.index, 1);
            return { lumenPortNumber, meds: meds, needsCaution };
          }
          return { lumenPortNumber, meds, needsCaution };
        });
        setLumenPorts(newLumenPortState);
      } else {
        setMessageToUser({ variant: "error", message: "Medications are not compatible" })
      }
    }
  };

  return (
    <Component>
      <Title>Result</Title>
      {lumenPorts.length != 0 ? (
        <DragDropContext onDragEnd={onDragMedication}>
          {lumenPorts.map(({ lumenPortNumber, meds, needsCaution }) => (
            <LumenDisplay
              key={lumenPortNumber}
              meds={meds}
              lumenPortNumber={lumenPortNumber}
              needsCaution={needsCaution}
            />
          ))}
        </DragDropContext>
      ) : (
          <NoMedsSelectedText>
            Search for medications/fluids then select a lumen count to determine
            compatibility.
          </NoMedsSelectedText>
        )}
      <ResetPrintContainer>
        <ErrorButton variant={"contained"} onClick={onReset}>
          Reset
        </ErrorButton>
        <PrintButton onClick={onPrintPage} variant={"contained"}>Print</PrintButton>
      </ResetPrintContainer>
    </Component>
  );
};
