import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";

const Component = styled.div`
  width: 16%;
`;

const Title = styled.h1`
  margin: 0;
  font-family: ${(props) => props.theme.font.family.paragraph};
  color: ${(props) => props.theme.colors.primaryText};
  text-align: center;
`;

type StyledLumenPortAmountButtonProps = {
  active: boolean;
};

const StyledLumenPortAmountButton = styled(Button) <
  StyledLumenPortAmountButtonProps
  >`
  && {
    width: 80%;
    margin: 1rem auto;
    display: block;
    background-color: ${(props) => (props.active ? "#dce3f1" : "transparent")};
    ${(props) => (props.active ? "border-color: black" : "")};
    font-family: ${(props) => props.theme.font.family.paragraph};
  }
`;

const StyledLumenPortAmountText = styled.p`
  font-size: 1.5rem;
  margin: 0;
`;

type LumenPortAmountButton = {
  number: number;
  active: boolean;
  onNumberOfLumenPortsChange: (newLumenPortNumber: number) => void;
};

const LumenPortAmountButton = ({
  number,
  active,
  onNumberOfLumenPortsChange,
}: LumenPortAmountButton) => {
  return (
    <StyledLumenPortAmountButton
      onClick={() => {
        onNumberOfLumenPortsChange(number);
      }}
      active={active}
      variant="outlined"
      key={number}
    >
      <StyledLumenPortAmountText>{number}</StyledLumenPortAmountText>
    </StyledLumenPortAmountButton>
  );
};

const lumenPortOptions = [1, 2, 3, 4, 5, 6];

type LumenCountSelectorProps = {
  numberOfLumenPorts: number;
  onNumberOfLumenPortsChange: (newLumenPortNumber: number) => void;
};

export const LumenCountSelector = ({
  numberOfLumenPorts,
  onNumberOfLumenPortsChange,
}: LumenCountSelectorProps) => {
  return (
    <Component>
      <Title>Lumen Count</Title>
      {lumenPortOptions.map((ele) => (
        <LumenPortAmountButton
          onNumberOfLumenPortsChange={onNumberOfLumenPortsChange}
          active={ele == numberOfLumenPorts}
          number={ele}
        />
      ))}
    </Component>
  );
};
