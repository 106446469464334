export const getAPIURL = () => {
  switch (window.location.host) {
    case "ivcompatibilitytool.com":
      return "https://ivct-core.ivcompatibilitytool.com";
    case "ict.dokku.explorator.ca":
      return "https://ict-core.dokku.explorator.ca";
    default:
      return "http://localhost:3005";
  }
};
